import { TableCell, Typography } from '@mui/material'
import { Interval } from 'luxon'
import { getClassNames } from '../../../../../utils'
import { DayCell } from '../../EventCalendar.Types'

type CalendarCellProps = {
  selection?: Interval | null
  cell: DayCell
  onMouseDown: (e: React.MouseEvent<HTMLElement>) => void
  onMouseMove: (e: React.MouseEvent<HTMLElement>) => void
  onMouseUp: (e: React.MouseEvent<HTMLElement>) => void
}
const CalendarCell = ({
  selection,
  cell,
  onMouseDown,
  onMouseMove,
  onMouseUp
}: CalendarCellProps) => {
  const isSelection =
    selection && cell.period?.intersection(selection)?.count('days')

  const classes = {
    weekend: cell.isWeekend,
    day: !!cell.period,
    filler: !cell.period,
    today: cell.isToday,
    selected: isSelection
  }

  return (
    <TableCell
      className={getClassNames(classes)}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {/* <CellMarksContainer marks={cell.marks}></CellMarksContainer> */}
      <Typography
        fontFamily='inherit'
        fontWeight={cell.isWeekend ? 'bold' : '100'}
      >
        {cell.period?.start.day}
      </Typography>
    </TableCell>
  )
}

export default CalendarCell
