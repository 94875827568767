import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DateTime, Interval } from 'luxon'
import { useEffect, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import deLocale from 'date-fns/locale/de'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { CalendarEvent } from '../../Dashboard/EventsCalendar/EventCalendar.Types'

type EventDialogProps = {
  event?: CalendarEvent
  opened: boolean
  onSave: (event: CalendarEvent) => Promise<CalendarEvent>
  onClose: () => void
}

const EventDialog = ({ event, opened, onClose, onSave }: EventDialogProps) => {
  const [name, setName] = useState<string | undefined>()
  const [color, setColor] = useState<string>('default')
  const [type, setType] = useState<string>('0')
  const [start, setStart] = useState<Date | undefined>()
  const [end, setEnd] = useState<Date | undefined>()

  const handleSave = () => {
    onSave({
      name: name,
      color: color,
      period: Interval.fromDateTimes(
        DateTime.fromJSDate(start || new Date()),
        DateTime.fromJSDate(end || new Date())
      )
    })
  }

  useEffect(() => {
    setName(event?.name)
    setStart(event?.period?.start?.toJSDate())
    setEnd(event?.period?.end?.toJSDate())
  }, [event, setName, setColor, setStart, setEnd])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <Dialog open={opened} onClose={onClose}>
        <DialogTitle sx={{ p: 2 }}>Event</DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          <Box display='flex' flexDirection={'column'} gap={2} sx={{ p: 1 }}>
            <TextField
              size='small'
              label='Name'
              variant='outlined'
              fullWidth
              value={name}
              onChange={newValue => setName(newValue.target.value)}
            />
            <GooglePlacesAutocomplete apiKey='AIzaSyBVpzLUYRyj-BA8oGL8KBGe0xslnp43lAU' />

            <Box display={'flex'} flexDirection={'row'} gap={1}>
              <DatePicker
                label='Start'
                value={start}
                onChange={newValue => setStart(newValue || undefined)}
                renderInput={params => (
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    {...params}
                  />
                )}
              />
              <DatePicker
                label='End'
                value={end}
                onChange={newValue => setEnd(newValue || undefined)}
                renderInput={params => (
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    {...params}
                  />
                )}
              />
            </Box>
            <Box display={'flex'} flexDirection={'row'} gap={1}>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='type-label'>Type</InputLabel>
                <Select
                  labelId='type-label'
                  id='type-select'
                  value={type}
                  label='Type'
                  onChange={event => setType(event.target.value)}
                >
                  <MenuItem value={'0'}>All</MenuItem>
                  <MenuItem value={'10'}>Party</MenuItem>
                  <MenuItem value={'20'}>Holiday</MenuItem>
                  <MenuItem value={'30'}>Trip</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='color-label'>Color</InputLabel>
                <Select
                  labelId='color-label'
                  id='color-select'
                  value={color}
                  label='Type'
                  onChange={event => setColor(event.target.value)}
                >
                  <MenuItem value={'default'}>Default</MenuItem>
                  <MenuItem value={'red'}>Red</MenuItem>
                  <MenuItem value={'blue'}>Blue</MenuItem>
                  <MenuItem value={'pink'}>Pink</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave}>Create</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  )
}

export default EventDialog
