import { Box } from '@mui/material'
import EventsCalendar from './EventsCalendar/EventsCalendar'
import ToDoList from './ToDoList'
import UpcomingEvents from './UpcomingEvents'

const Dashboard = () => {
  return (
    <Box gap={3} display='flex' flexDirection='column'>
      <EventsCalendar></EventsCalendar>
      <Box display={'flex'} flexDirection={'row'}>
        <Box flex={2}>
          <UpcomingEvents></UpcomingEvents>
        </Box>
        <Box flex={1}>
          <ToDoList></ToDoList>
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard
