import { ArrowDropDown } from '@mui/icons-material'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClick}>
        <Typography variant='button'>{i18n.language?.toUpperCase()}</Typography>
        <ArrowDropDown />
      </Button>

      <Menu
        className='language-selector'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeLanguage('en')}>
          <ReactCountryFlag countryCode='GB' style={{ marginRight: '10px' }} />{' '}
          EN
        </MenuItem>
        <MenuItem onClick={() => handleChangeLanguage('de')}>
          <ReactCountryFlag countryCode='DE' style={{ marginRight: '10px' }} />{' '}
          DE
        </MenuItem>
      </Menu>
    </>
  )
}

export default LanguageSelector
