import { IconButton, TableCell, TableHead, TableRow } from '@mui/material'
import { getClassNames } from '../../../../utils'
import { CALENDAR_MAX_NUMBER_OF_DAYS } from '../EventsCalendar.Settings'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

type CalendarHeaderProps = {
  onShowMore?: () => void
}

const CalendarHeader = ({ onShowMore }: CalendarHeaderProps) => {
  const [t] = useTranslation()
  const TABLE_COLUMNS = Array(CALENDAR_MAX_NUMBER_OF_DAYS)
    .fill(CALENDAR_MAX_NUMBER_OF_DAYS)
    .map((_, idx) => t('DAYS-OF-WEEK', { returnObjects: true })[idx % 7])
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {onShowMore && (
            <Box padding={1}>
              <IconButton onClick={onShowMore} size={'small'}>
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
          )}
        </TableCell>
        {TABLE_COLUMNS.map((day, idx) => (
          <TableCell
            className={getClassNames({ weekend: idx % 7 > 4 })}
            key={idx}
          >
            {day}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default CalendarHeader
