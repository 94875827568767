import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CalendarEvent, DayCell, MonthRow } from '../../EventCalendar.Types'
import './EventBar.scss'

type CellEventProps = {
  event: CalendarEvent
  month: MonthRow
  cellWidth: number
  offset: number
}

const CellEvent = ({ event, month, cellWidth, offset }: CellEventProps) => {
  let startCell = month.cells.find(cell =>
    cell.period?.start.equals(event.period.start)
  )
  let endCell = month.cells.find(cell =>
    cell.period?.end.equals(event.period.end)
  )

  let lastCell: DayCell | undefined
  month.cells.forEach(cell => {
    if (cell.period) {
      lastCell = cell
    }
  })
  const firstCell = month.cells.find(cell => cell.period)

  if (!endCell && lastCell?.period) {
    if (lastCell?.period?.end <= event.period.end) {
      endCell = lastCell
    }
  }

  if (!startCell && firstCell?.period) {
    if (firstCell?.period?.start >= event.period.start) {
      startCell = firstCell
    }
  }

  if (startCell && endCell) {
    const monthContainsStartOfEvent = startCell?.period?.start.equals(
      event?.period?.start
    )
    const monthContainsEndOfEvent = endCell?.period?.end.equals(
      event?.period?.end
    )

    const width = (endCell.index - startCell.index + 1) * cellWidth - 2
    const left = startCell.index * cellWidth + offset
    return (
      <Box
        className='cell-event'
        sx={{
          left: `${left}px`,
          width: `${width}px`
        }}
      >
        <Typography
          className='event-info'
          sx={{
            flex: 1,
            paddingLeft: monthContainsStartOfEvent ? `${cellWidth}px` : '4px',
            paddingRight:
              width <= cellWidth
                ? '0px'
                : monthContainsEndOfEvent
                ? `${cellWidth}px`
                : '4px',
            borderTopLeftRadius: monthContainsStartOfEvent ? '4px' : 'none',
            borderBottomLeftRadius: monthContainsStartOfEvent ? '4px' : 'none',
            borderTopRightRadius: monthContainsEndOfEvent ? '4px' : 'none',
            borderBottomRightRadius: monthContainsEndOfEvent ? '4px' : 'none'
          }}
        >
          {width > 100 && monthContainsStartOfEvent ? event.name : ''}
        </Typography>
        <Typography
          className='event-start-cell'
          fontWeight={startCell.isWeekend ? 'bold' : 'normal'}
          align='center'
          width={`${cellWidth}px`}
        >
          {startCell.period?.start.day}
        </Typography>
        <Typography
          className='event-end-cell'
          fontWeight={endCell.isWeekend ? 'bold' : 'normal'}
          align='center'
          width={`${cellWidth}px`}
        >
          {endCell.period?.start.day}
        </Typography>
      </Box>
    )
  } else {
    return <></>
  }
}

export default CellEvent
