import { useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router'
import routes from '../routes/Routes'
import { useRecoilState } from 'recoil'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import sessionState from '../states/sessionState'
import WhileAuthenticating from '../components/WhileAuthenticating'
import HomePage from './HomePage'
import SignInPage from './SignInPage'
import SignUpPage from './SignUpPage'
import { Container } from '@mui/material'
import UserProfilePage from './UserProfilePage'
import { getUserProfile } from '../services/User/User'

const App = () => {
  const [session, setSession] = useRecoilState(sessionState)

  // Subscribe to firebase auth
  useEffect(
    () =>
      onAuthStateChanged(getAuth(), async resultUser => {
        if (resultUser) {
          const userProfile = await getUserProfile(resultUser)
          setSession({
            isAuthenticating: false,
            authenticated: !!userProfile,
            user: userProfile
          })
        }
      }),
    [setSession]
  )

  return (
    <Container maxWidth='lg'>
      <WhileAuthenticating>
        {session.authenticated ? (
          <Routes>
            <Route path={routes.home} element={<HomePage />} />
            <Route path={routes.profile} element={<UserProfilePage />} />
            <Route path='*' element={<Navigate to={routes.home} />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={routes.signin} element={<SignInPage />} />
            <Route path={routes.signup} element={<SignUpPage />} />
            <Route path='*' element={<Navigate to={routes.signin} />} />
          </Routes>
        )}
      </WhileAuthenticating>
    </Container>
  )
}

export default App
