import { ReactNode } from 'react'
import {
  AppBar as MaterialAppBar,
  Box,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'

import UserAvatar from './UserAvatar'
import LanguageSelector from './LanguageSelector'
import './AppBar.scss'

type Props = {
  title?: string
  linkBackTo?: string
  actions?: ReactNode
}

const AppBar = (props: Props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40
  })

  return (
    <MaterialAppBar
      elevation={trigger ? 4 : 0}
      position='sticky'
      color='transparent'
    >
      <Toolbar sx={{ p: 0 }} className='app-bar'>
        <Box flex='auto'>
          <Typography variant='h6'>{props.title}</Typography>
        </Box>
        <LanguageSelector></LanguageSelector>
        <UserAvatar></UserAvatar>
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
