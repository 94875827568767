// import { makeStyles } from '@mui/styles'
import AppBar from '../../components/AppBar/AppBar'
import UserProfile from '../../components/UserProfile'

// const useStyle = makeStyles({
//   container: {
//     maxWidth: '500px',
//     width: '100%'
//   }
// })

const UserProfilePage = () => {
  return (
    <>
      <AppBar title='Settings' />
      <UserProfile></UserProfile>
    </>
  )
}

export default UserProfilePage
