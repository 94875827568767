import { debounce } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import sessionState from '../../states/sessionState'

const useStyle = makeStyles({
  image: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '70px',
    height: '70px',
    margin: '-35px -35px',
    filter: 'grayscale(100%)'
  }
})

const WhileAuthenticating = ({ children }: { children: any }) => {
  const session = useRecoilValue(sessionState)
  const [loading, setLoading] = useState(true)
  const setLoadingDebounce = debounce(setLoading, 500)

  const classes = useStyle()

  useEffect(() => {
    if (!session.isAuthenticating) {
      setLoadingDebounce(false)
    }
  }, [session.isAuthenticating, setLoadingDebounce])

  return loading ? (
    <img alt="Where We Go App" className={classes.image} src='/images/logo.png'></img>
  ) : (
    children
  )
}

export default WhileAuthenticating
