import { Avatar, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
// import {
//   getAuth,
//   createUserWithEmailAndPassword,
//   updateProfile
// } from 'firebase/auth'
// import routes from '../../routes/Routes'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import sessionState from '../../states/sessionState'

const UserProfile = () => {
  const { register, handleSubmit } = useForm<{
    firstName: string
    lastName: string
    username: string
    password: string
  }>()

  const session = useRecoilValue(sessionState)

  // const [error, setError] = useState<string | undefined>()
  const [userPhoto, setUserPhoto] = useState<string | undefined>()

  const save = ({ username, password, lastName, firstName }: any) => {
    // if (session.user) {
    //   updateProfile(session.user, {
    //     displayName: `${firstName} ${lastName}`
    //   })
    // }
  }

  const handleImageUploaded = (e: any) => {
    var reader = new FileReader()
    reader.onload = function () {
      setUserPhoto(reader.result + '')
    }
    reader.readAsDataURL(e.target.files[0])
  }

  return (
    <Box display='flex' flexDirection={'column'} gap={1} padding={1}>
      <form onSubmit={handleSubmit(save)}>
        <Box display='flex' flexDirection={'column'} gap={2} sx={{ p: 2 }}>
          <Box display='flex' flexDirection={'column'} gap={1}>
            <Typography variant='h5'>User details</Typography>
            <TextField
              label='First name'
              variant='outlined'
              autoComplete='off'
              fullWidth
              {...register('firstName', {
                required: true
              })}
            />

            <TextField
              label='Last name'
              variant='outlined'
              autoComplete='off'
              fullWidth
              {...register('lastName', {
                required: true
              })}
            />

            <TextField
              label='Email'
              variant='outlined'
              autoComplete='off'
              fullWidth
              {...register('username', {
                required: true
              })}
            />
            <Box>
              <Typography>Picture</Typography>
              <Box display='flex' flexDirection='row' gap={1}>
                <Avatar
                  variant='rounded'
                  src={userPhoto || session.user?.photoURL || undefined}
                ></Avatar>
                <Button variant='contained' component='label'>
                  Upload
                  <input
                    hidden
                    accept='image/*'
                    multiple
                    type='file'
                    onChange={handleImageUploaded}
                  />
                </Button>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              justifyContent={'space-between'}
            >
              <Button variant='contained' type='submit' color='primary'>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default UserProfile
