/**
 * Define the different parts of your state here
 * using https://recoiljs.org/
 */
import { atom } from 'recoil'
import Database from '../types/Database'

export default atom<{
  user?: Database.UserProfile | undefined
  isAuthenticating: boolean
  authenticated: boolean
}>({
  key: 'session',
  default: {
    isAuthenticating: true,
    authenticated: false
  }
})
