import { useTranslation } from 'react-i18next'
import AppBar from '../../components/AppBar/AppBar'
import Dashboard from '../../components/Dashboard/Dashboard'

const HomePage = () => {
  const [t] = useTranslation()

  return (
    <>
      <AppBar title={t('Home')} />
      <Dashboard></Dashboard>
    </>
  )
}

export default HomePage
