import { initializeApp } from 'firebase/app'
import // getFirestore,
// connectFirestoreEmulator,
// enableMultiTabIndexedDbPersistence
'firebase/firestore'
import // getAuth,
// connectAuthEmulator
// signInWithCredential,
// EmailAuthProvider
'firebase/auth'

// the values to initialize the firebase app can be found in your firebase project
const firebaseConfig = {
  apiKey: 'AIzaSyCXbpcjSTOESORir1amzcdJpoqmN19eXsY',
  authDomain: 'where-we-go-c6c84.firebaseapp.com',
  databaseURL: 'https://where-we-go-c6c84.firebaseio.com',
  projectId: 'where-we-go-c6c84',
  storageBucket: 'where-we-go-c6c84.appspot.com',
  messagingSenderId: '884516266661',
  appId: '1:884516266661:web:3ff29437aaa1f4aa09a34c',
  measurementId: 'G-4683Y9LXJ3'
}

const initFirebase = async () => {
  try {
    // const app = initializeApp(firebaseConfig)
    initializeApp(firebaseConfig)
    // const firestore = getFirestore(app)
    // const auth = getAuth(app)

    if (process.env.NODE_ENV !== 'production') {
      // connectAuthEmulator(auth, 'http://localhost:9099')
      // connectFirestoreEmulator(firestore, 'localhost', 8080)
      // enableMultiTabIndexedDbPersistence(firestore)
      /**
       * The following code logins the user automatically to speed up development.
       * For this to work you first need to create a user and then run the command
       * yarn emulator:export, then import the data when starting the emulator
       * yarn firebase emulators:start --only firestore,auth --import=firestore_mock_data
       */
      // signInWithCredential(
      //   auth,
      //   EmailAuthProvider.credential('john@doe.com', '123123')
      // )
    }
  } catch (err) {
    console.error(err)
    return err
  }
}

export default initFirebase
