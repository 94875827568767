import { Home, Logout, Settings } from '@mui/icons-material'
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import routes from '../../routes'
import { userLogOut } from '../../services/User/User'
import sessionState from '../../states/sessionState'

const UserAvatar = ({ history }: any) => {
  const session = useRecoilValue(sessionState)
  const { user } = session
  const navigate = useNavigate()
  const [t] = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    userLogOut()
    handleClose()
  }

  const handleProfile = () => {
    navigate(routes.profile)
    handleClose()
  }

  const handleHome = () => {
    navigate(routes.home)
    handleClose()
  }

  return (
    <div>
      <IconButton
        onClick={handleClick}
        title={`${user?.firstName} ${user?.lastName}`}
      >
        <Avatar src={user?.photoURL || undefined}></Avatar>
      </IconButton>

      <Menu
        className='user-avatar'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleHome}>
          <Home sx={{ marginRight: 1 }} fontSize='small' /> {t('Home')}
        </MenuItem>
        <MenuItem onClick={handleProfile}>
          <Settings sx={{ marginRight: 1 }} fontSize='small' /> {t('Profile')}
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <Logout sx={{ marginRight: 1 }} fontSize='small' /> {t('Logout')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserAvatar
