import { CalendarEvent, MonthRow } from '../../EventCalendar.Types'
import CellEvent from './EventBar'

type EventsBarContainerProps = {
  events: CalendarEvent[] | null
  month: MonthRow
  cellWidth: number
  offset: number
}

const EventsBarContainer = ({
  events,
  month,
  cellWidth,
  offset
}: EventsBarContainerProps) => {
  return (
    <>
      {events?.map(event => (
        <CellEvent
          month={month}
          event={event}
          cellWidth={cellWidth}
          offset={offset}
        />
      ))}
    </>
  )
}

export default EventsBarContainer
