import SignIn from '../../components/SignIn'
import { makeStyles } from '@mui/styles'
import { Paper } from '@mui/material'
import { Box } from '@mui/system'

const useStyle = makeStyles({
  container: {
    maxWidth: '500px',
    width: '100%'
  }
})

const SignInPage = () => {
  const classes = useStyle()
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent={'center'}
      margin={2}
    >
      <Paper className={classes.container} elevation={10}>
        <SignIn></SignIn>
      </Paper>
    </Box>
  )
}

export default SignInPage
