import { getAuth, signOut, User } from 'firebase/auth'
import db from '../../db/db'
import Database from '../../types/Database'

export function userLogOut() {
  signOut(getAuth())
}

export function getUserProfile(
  user: User
): Promise<Database.UserProfile | undefined> {
  return db.getById('Users', user.uid)
}
