import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import EventCard from './EventCard'

const UpcomingTrips = () => {
  const [t] = useTranslation()

  return (
    <>
      <Box sx={{ paddingLeft: 3 }}>
        <Typography variant='h6'>{t('Upcoming events')}</Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={3}
        marginTop={3}
        flexWrap={'wrap'}
      >
        <EventCard />
        <EventCard />
        <EventCard />
        <EventCard />
      </Box>
    </>
  )
}

export default UpcomingTrips
