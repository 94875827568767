import { TableCell, TableRow } from '@mui/material'
import { Interval } from 'luxon'
import CalendarCell from './CalendarCell/CalendarCell'
import { DayCell, MonthRow } from '../EventCalendar.Types'
import EventsBarContainer from './Events'
import { useTranslation } from 'react-i18next'
import { useElementSize } from 'usehooks-ts'

type CalendarRowProps = {
  month: MonthRow
  selection?: Interval | null
  onMouseDown?: (cell: DayCell, e: React.MouseEvent<HTMLElement>) => void
  onMouseMove?: (cell: DayCell, e: React.MouseEvent<HTMLElement>) => void
  onMouseUp?: (cell: DayCell, e: React.MouseEvent<HTMLElement>) => void
}
const CalendarRow = ({
  month,
  selection,
  onMouseDown,
  onMouseMove,
  onMouseUp
}: CalendarRowProps) => {
  const [t] = useTranslation()
  const [rowReference, rowSize] = useElementSize()
  const [rowMonthReference, rowMonthSize] = useElementSize()

  return (
    <TableRow
      ref={rowReference}
      className={'month' + (month.isCurrentMonth ? ' current-month' : '')}
    >
      <TableCell className='month-name' ref={rowMonthReference}>
        {t('MONTHS', { returnObjects: true })[month.period.start.month - 1]} '
        {month.year % 100}
      </TableCell>
      {month.cells.map((cell, cellIdx) => (
        <CalendarCell
          key={cellIdx}
          cell={cell}
          selection={selection}
          onMouseDown={e => onMouseDown && onMouseDown(cell, e)}
          onMouseUp={e => onMouseUp && onMouseUp(cell, e)}
          onMouseMove={e => onMouseMove && onMouseMove(cell, e)}
        />
      ))}
      <EventsBarContainer
        month={month}
        events={month.events}
        cellWidth={(rowSize.width - rowMonthSize.width) / month.cells.length}
        offset={rowMonthSize.width}
      />
    </TableRow>
  )
}

export default CalendarRow
