import { Alert, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'
import routes from '../../routes/Routes'
import { Box } from '@mui/system'
import { useState } from 'react'

const SignUp = () => {
  const { register, handleSubmit } = useForm<{
    firstName: string
    lastName: string
    username: string
    password: string
  }>()

  const [error, setError] = useState<string | undefined>()

  const signUp = ({ username, password, lastName, firstName }: any) => {
    createUserWithEmailAndPassword(getAuth(), username, password)
      .then(user => {
        updateProfile(user.user, {
          displayName: `${firstName} ${lastName}`
        })
      })
      .catch(err => {
        setError(err.code)
      })
  }
  return (
    <Box display='flex' flexDirection={'column'} gap={1} padding={1}>
      <Typography variant='h4'>Register</Typography>
      <form onSubmit={handleSubmit(signUp)}>
        <Box display='flex' flexDirection={'column'} gap={1}>
          <TextField
            label='First name'
            variant='outlined'
            autoComplete='off'
            fullWidth
            {...register('firstName', {
              required: true
            })}
          />

          <TextField
            label='Last name'
            variant='outlined'
            autoComplete='off'
            fullWidth
            {...register('lastName', {
              required: true
            })}
          />

          <TextField
            label='Email'
            variant='outlined'
            autoComplete='off'
            fullWidth
            {...register('username', {
              required: true
            })}
          />
          <TextField
            label='Password'
            variant='outlined'
            autoComplete='off'
            type='password'
            fullWidth
            {...register('password', {
              required: true
            })}
          />
          {error && <Alert severity='error'>{error}</Alert>}
          <Box
            display='flex'
            flexDirection='row'
            justifyContent={'space-between'}
          >
            <Button type='submit' color='primary'>
              Signup
            </Button>
            <Link to={routes.signin}>Sign in</Link>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default SignUp
