import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

const ToDoList = () => {
  const [t] = useTranslation()

  return (
    <>
      <Box sx={{ paddingLeft: 3 }}>
        <Typography variant='h6'>{t('TODOs')}</Typography>
      </Box>
      <Box marginTop={3}>todo 1</Box>
    </>
  )
}

export default ToDoList
