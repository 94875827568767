import React, { useEffect, useState } from 'react'
import './EventsCalendar.scss'
import { DateTime, Interval } from 'luxon'
import { Table, TableBody } from '@mui/material'
import ContextualMenu from './ConextualMenu'
import MarkDialog from '../../Dialogs/MarkDialog'
import {
  CalendarEvent,
  CalendarMark,
  DayCell,
  MonthRow
} from './EventCalendar.Types'
import { CALENDAR_MAX_NUMBER_OF_DAYS } from './EventsCalendar.Settings'
import CalendarHeader from './CalendarHeader'
import CalendarRow from './CalendarRow'
import EventDialog from '../../Dialogs/EventDialog'
import CalendarFooter from './CalendarFooter'
import { useTranslation } from 'react-i18next'
import { addEvent, getEvents } from '../../../services/Events/EventsService'
import { useRecoilValue } from 'recoil'
import sessionState from '../../../states/sessionState'
import { Timestamp } from 'firebase/firestore'
import Database from '../../../types/Database'

const periodForCell = (month: Interval, cell: number) => {
  const dayInMonth = cell - month.start.weekday + 2

  if (dayInMonth > 0 && dayInMonth <= month.count('days')) {
    const beginningOfDay = month.start.plus({ days: dayInMonth - 1 })
    return Interval.fromDateTimes(beginningOfDay, beginningOfDay.endOf('day'))
  }

  return null
}

const EventsCalendar = () => {
  // const [startDate, setStartDate] = useState(new Date());
  const session = useRecoilValue(sessionState)
  const { user } = session
  const [period, setPeriod] = useState<Interval>(
    Interval.fromDateTimes(
      DateTime.now().minus({ months: 1 }),
      DateTime.now().plus({ months: 4 })
    )
  )
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null | undefined>()
  const [content, setContent] = useState<MonthRow[]>([])
  const [selection, setSelection] = useState<Interval | null>()
  const [marks, setMarks] = useState<CalendarMark[]>([])
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [mark, setMark] = useState<CalendarMark | undefined>()
  const [event, setEvent] = useState<CalendarEvent | undefined>()
  const [markDialogOpened, setMarkDialogOpened] = useState<boolean>(false)
  const [eventDialogOpened, setEventDialogOpened] = useState<boolean>(false)
  const [t] = useTranslation()

  useEffect(() => {
    const TABLE_COLUMNS = Array(CALENDAR_MAX_NUMBER_OF_DAYS).fill(
      CALENDAR_MAX_NUMBER_OF_DAYS
    )

    const today = DateTime.now().startOf('day')
    const content: MonthRow[] = []

    let month = Interval.fromDateTimes(
      period.start.startOf('month'),
      period.start.endOf('month')
    )

    while (period.overlaps(month)) {
      const isCurrentMonth =
        today.startOf('month').toMillis() === month.start.toMillis()

      const data: MonthRow = {
        period: month,
        name: t('MONTHS')[month?.start?.month],
        year: month?.start?.year,
        id: t('MONTHS')[month?.start?.month] + month?.start?.year,
        isCurrentMonth,
        // eslint-disable-next-line
        marks: marks.filter(mark =>
          mark.period.intersection(month)?.count('days')
        ),
        // eslint-disable-next-line
        events: events.filter(event =>
          event.period.intersection(month)?.count('days')
        ),
        // eslint-disable-next-line
        cells: TABLE_COLUMNS?.map((_, cellIndex) => {
          const cellPeriod = periodForCell(month, cellIndex)
          const isWeekend =
            cellPeriod?.end?.weekday === 6 ||
            cellPeriod?.end?.weekday === 7 ||
            false

          const cellMarks = cellPeriod
            ? marks.filter(mark =>
                mark.period.intersection(cellPeriod)?.count('days')
              )
            : null
          const cellEvents = cellPeriod
            ? events.filter(event =>
                event.period.intersection(cellPeriod)?.count('days')
              )
            : null
          const isToday = cellPeriod?.contains(today) || false

          return {
            index: cellIndex,
            period: cellPeriod,
            marks: cellMarks,
            events: cellEvents,
            isToday,
            isWeekend
          }
        })
      }
      content.push(data)
      const firstDayNextMonth = month.start.plus({ months: 1 })
      month = Interval.fromDateTimes(
        firstDayNextMonth.startOf('month'),
        firstDayNextMonth.endOf('month')
      )
    }
    setContent(content)
  }, [period, marks, events, t, setContent])

  useEffect(() => {
    getEvents().then(events => {
      setEvents(
        events.map(e => ({
          name: e.name,
          period: Interval.fromDateTimes(
            DateTime.fromMillis(e.start.toMillis()),
            DateTime.fromMillis(e.end.toMillis())
          )
        }))
      )
    })
  }, [period, setEvents])

  const handleSelectionStart = (
    cell: DayCell,
    e: React.MouseEvent<HTMLElement>
  ) => {
    if (
      cell.period &&
      selection &&
      selection.intersection(cell.period)?.count('days')
    ) {
      setMenuAnchor(e.currentTarget)
    } else {
      setSelection(cell.period)
    }
  }

  const handleSelectionOver = (
    cell: DayCell,
    e: React.MouseEvent<HTMLElement>
  ) => {
    if (e.buttons === 1) {
      if (cell?.period && selection) {
        if (selection.start < cell?.period?.end) {
          setSelection(Interval.fromDateTimes(selection.start, cell.period.end))
        } else if (selection.start > cell.period.end) {
          setSelection(Interval.fromDateTimes(cell.period.start, selection.end))
        }
      }
    }
  }

  const handleSelectionStop = (
    cell: DayCell,
    e: React.MouseEvent<HTMLElement>
  ) => {
    if (selection) {
    }
  }

  const handleMenuSelected = (action: string) => {
    if (selection) {
      switch (action) {
        case 'create-event': {
          setEvent({
            period: selection
          })
          setEventDialogOpened(true)
          break
        }
        case 'create-mark': {
          setMark({
            period: selection,
            color: 'blue'
          })
          setMarkDialogOpened(true)
          break
        }
      }
    }
  }

  const handleCloseMenu = () => {
    setMenuAnchor(undefined)
  }

  const handleCloseMarkDialog = () => {
    setSelection(null)
    handleCloseMenu()
    setMarkDialogOpened(false)
  }

  const handleMarkDialogSaved = async (mark: CalendarMark) => {
    setMarks(marks.concat([mark]))
    handleCloseMarkDialog()
    return mark
  }

  const handleCloseEventDialog = () => {
    setSelection(null)
    handleCloseMenu()
    setEventDialogOpened(false)
  }

  const handleEventDialogSaved = async (calendarEvent: CalendarEvent) => {
    if (user && calendarEvent.name) {
      const event: Database.Event = {
        name: calendarEvent.name,
        start: Timestamp.fromMillis(calendarEvent.period.start.toMillis()),
        end: Timestamp.fromMillis(calendarEvent.period.end.toMillis()),
        color: calendarEvent.color || 'unspecified',
        type: calendarEvent.type || 'unspecified',
        owner: user.id
      }

      await addEvent(event)
      setEvents(events.concat([calendarEvent]))
      handleCloseEventDialog()
    }
    return calendarEvent
  }

  const scrollUp = () => {
    setPeriod(
      Interval.fromDateTimes(
        period.start.minus({ months: 3 }),
        period.end.minus({ months: 3 })
      )
    )
  }

  const scrollDown = () => {
    setPeriod(
      Interval.fromDateTimes(
        period.start.plus({ months: 3 }),
        period.end.plus({ months: 3 })
      )
    )
  }

  return (
    <>
      <Table className='events-calendar'>
        <CalendarHeader onShowMore={scrollUp} />
        <TableBody>
          {content.map(month => (
            <CalendarRow
              key={month.id}
              selection={selection}
              month={month}
              onMouseDown={handleSelectionStart}
              onMouseMove={handleSelectionOver}
              onMouseUp={handleSelectionStop}
            ></CalendarRow>
          ))}
          <CalendarFooter onShowMore={scrollDown} />
        </TableBody>
      </Table>
      <ContextualMenu
        anchor={menuAnchor}
        onClose={handleCloseMenu}
        onSelect={handleMenuSelected}
      ></ContextualMenu>
      <MarkDialog
        mark={mark}
        opened={markDialogOpened}
        onClose={handleCloseMarkDialog}
        onSave={handleMarkDialogSaved}
      ></MarkDialog>
      <EventDialog
        event={event}
        opened={eventDialogOpened}
        onClose={handleCloseEventDialog}
        onSave={handleEventDialogSaved}
      ></EventDialog>
    </>
  )
}

export default EventsCalendar
