import { Alert, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import routes from '../../routes/Routes'
import { Box } from '@mui/system'
import { useState } from 'react'

const SignIn = () => {
  const [error, setError] = useState<string | undefined>()

  const { register, handleSubmit } = useForm<{
    username: string
    password: string
  }>()

  const signin = ({ username, password }: any) => {
    signInWithEmailAndPassword(getAuth(), username, password).catch(err => {
      setError(err.code)
    })
  }

  return (
    <Box display='flex' flexDirection={'column'} gap={1} padding={1}>
      <Typography variant='h4'>Sign in</Typography>
      <form onSubmit={handleSubmit(signin)}>
        <Box display='flex' flexDirection={'column'} gap={1}>
          <TextField
            label='Email'
            variant='outlined'
            fullWidth
            {...register('username', {
              required: true
            })}
          />
          <TextField
            label='Password'
            variant='outlined'
            type='password'
            fullWidth
            {...register('password', {
              required: true
            })}
          />
          {error && <Alert severity='error'>{error}</Alert>}

          <Box
            display='flex'
            flexDirection='row'
            justifyContent={'space-between'}
          >
            <Button type='submit' color='primary'>
              Login
            </Button>
            <Link color='primary' to={routes.signup}>
              Create account
            </Link>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default SignIn
