import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import HikingIcon from '@mui/icons-material/Hiking'
import './ContextualMenu.scss'
import { useTranslation } from 'react-i18next'

type ContextualMenuProps = {
  anchor: HTMLElement | null | undefined
  onClose: () => void
  onSelect: (action: string) => void
}
const ContextualMenu = ({ anchor, onClose, onSelect }: ContextualMenuProps) => {
  const [t] = useTranslation()

  return (
    <Menu
      id='events-calendar-contextual-menu'
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={Boolean(anchor)}
      onClose={onClose}
    >
      <MenuItem onClick={() => onSelect('create-event')}>
        <HikingIcon /> {t('Create event')}
      </MenuItem>
      <MenuItem onClick={() => onSelect('create-mark')}>
        <MoreHorizIcon /> {t('Mark period')}
      </MenuItem>
    </Menu>
  )
}

export default ContextualMenu
