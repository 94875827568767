import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { useEffect, useState } from 'react'
import { CalendarMark } from '../../Dashboard/EventsCalendar/EventCalendar.Types'

type MarkDialogProps = {
  mark?: CalendarMark
  opened: boolean
  onSave: (mark: CalendarMark) => Promise<CalendarMark>
  onClose: () => void
}

const MarkDialog = ({ mark, opened, onClose, onSave }: MarkDialogProps) => {
  const [data, setData] = useState<CalendarMark>(
    mark || {
      period: Interval.fromDateTimes(
        DateTime.now().startOf('day'),
        DateTime.now().endOf('day')
      ),
      color: 'yellow'
    }
  )

  useEffect(() => {
    if (mark) {
      setData(mark)
    }
  }, [mark, setData])

  return (
    <Dialog open={opened} onClose={onClose}>
      <DialogTitle>Subscribe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Email Address'
          type='email'
          fullWidth
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(data)}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MarkDialog
