import db from '../../db/db'
import Database from '../../types/Database'

export function addEvent(event: Database.Event) {
  db.addItem('Events', event)
}

export function getEvents(): Promise<Database.Event[]> {
  return db.query({
    collection: 'Events',
    where: []
  })
}
