import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import { CALENDAR_MAX_NUMBER_OF_DAYS } from '../EventsCalendar.Settings'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

type CalendarFooterProps = {
  onShowMore?: () => void
}

const CalendarFooter = ({ onShowMore }: CalendarFooterProps) => {
  return (
    <TableRow>
      <TableCell>
        {onShowMore && (
          <Box padding={1}>
            <IconButton sx={{ m: 0 }} onClick={onShowMore} size={'small'}>
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
        )}
      </TableCell>
      <TableCell colSpan={CALENDAR_MAX_NUMBER_OF_DAYS} />
    </TableRow>
  )
}

export default CalendarFooter
